<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title>缓存管理</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <div
                  class="d-flex"
                >
                  <v-avatar
                    class="elevation-1"
                    rounded
                    color="primary"
                  >
                    <span class="white--text text-h5">节假</span>
                  </v-avatar>
                  <div
                    class="ms-3"
                  >
                    <p
                      class="mb-0"
                    >
                      节假日缓存
                    </p>
                    <v-btn
                      x-small
                    >
                      清空
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <div
                  class="d-flex"
                >
                  <v-avatar
                    class="elevation-1"
                    rounded
                    color="primary"
                  >
                    <span class="white--text text-h5">订阅</span>
                  </v-avatar>
                  <div
                    class="ms-3"
                  >
                    <p
                      class="mb-0"
                    >
                      订阅缓存
                    </p>
                    <v-btn
                      x-small
                    >
                      清空
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <div
                  class="d-flex"
                >
                  <v-avatar
                    class="elevation-3"
                    rounded
                    color="error"
                  >
                    <span class="white--text text-h5">ALL</span>
                  </v-avatar>
                  <div
                    class="ms-3"
                  >
                    <p
                      class="mb-0"
                    >
                      所有缓存
                    </p>
                    <v-btn
                      x-small
                    >
                      清空所有
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Settings',
}
</script>

<style scoped>

</style>
